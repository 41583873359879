<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Sub Kelompok Account</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"
          >/ Keuangan / Master / Sub Kelompok Account</span
        >
      </div>
    </div>
    <div class="card bg-white">
      <Toolbar>
        <template #start>
          <Dropdown
            v-model="kelompokId"
            :filter="true"
            :options="list_kelompok_account"
            class="mr-2"
            optionValue="id"
            optionLabel="nama"
            placeholder="Pilih Kelompok Account"
            @change="onLoadGridData"
          />
          <Dropdown
            v-model="subKelompokId"
            :filter="true"
            :options="list_sub_kelompok_account"
            optionValue="id"
            optionLabel="nama"
            placeholder="Pilih Sub Kelompok Account"
            @change="onLoadGridSubData"
          />
        </template>
        <template #end>
          <Button
            :disabled="kelompokId === 0"
            icon="pi pi-plus"
            label="Sub Kelompok Account"
            @click="onShowDialog"
          />
        </template>
      </Toolbar>
      <grid-sub-kelompok-account
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onShowDialog"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      :header="
        form && form.id > 0
          ? 'Edit Sub Kelompok Account'
          : 'Tambah Sub Kelompok Account'
      "
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <form-sub-kelompok-account
        :item="form"
        :kelompok-id="kelompokId"
        :sub-id="subKelompokId"
        :loading="isLoadingSave"
        :list-kelompok-account="list_kelompok_account"
        :list-sub-kelompok-account="list_sub_kelompok_account"
        @save="onSaveData"
        @savesub="onSaveDataSub"
        @close="dialogSave = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Sub Kelompok Account"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Kode Sub Kelompok <strong>{{ form.kode }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="dialogDelete = false"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/errorhandler'
import AccountService from '@/services/AccountService'
import GridSubKelompokAccount from '@/components/keuangan/GridSubKelompokAccount'
import FormSubKelompokAccount from '@/components/keuangan/FormSubKelompokAccount'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    GridSubKelompokAccount,
    FormSubKelompokAccount,
  },
  data() {
    return {
      kelompokId: 0,
      subKelompokId: 0,
      dataService: null,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      list_kelompok_account: [],
      list_sub_kelompok_account: [],
      submitted: false,
      dialogSave: false,
      dialogDelete: false,
      totalRecords: 0,
      form: {
        id: 0,
        kode: null,
        nama: null,
      },
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
  },
  mounted() {
    this.onLoadKelompokAccount()
  },
  computed: {
    parent() {
      if (this.subKelompokId > 0) {
        return this.subKelompokId
      } else {
        return this.kelompokId
      }
    },
  },
  methods: {
    onLoadKelompokAccount() {
      this.dataService
        .getAccountGroup()
        .then((res) => {
          this.list_kelompok_account = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      this.subKelompokId = 0
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateAccountSGroup(
          this.parent,
          page,
          perPage,
          sortField,
          sortOrder,
          filters
        )
        .then((res) => {
          this.items = res.data.data
          this.list_sub_kelompok_account = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Sub Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadGridSubData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateAccountSGroup(
          this.subKelompokId,
          page,
          perPage,
          sortField,
          sortOrder,
          filters
        )
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Sub Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
    onShowDialog(data) {
      this.form = Object.assign({}, data)
      this.list_sub_kelompok_account
      this.dialogSave = true
    },
    onSaveData(data) {
      let item = {}
      item.kode = data.kode
      item.nama = data.nama
      item.group_id = this.kelompokId
      item.sub_id = 0

      this.isLoadingSave = true
      if (data.id > 0) {
        this.dataService
          .updateAccountSGroupNoSub(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Sub Kelompok Account',
                detail: 'Sub Kelompok Account berhasil diperbarui.',
                life: 3000,
              })
              if (this.subKelompokId > 0) {
                this.onLoadGridSubData()
              } else {
                this.onLoadGridData()
              }
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sub Kelompok Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.dataService
          .addAccountSGroupNoSub(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Sub Kelompok Account',
                detail: 'Sub Kelompok Account berhasil ditambahkan.',
                life: 3000,
              })
              if (this.subKelompokId > 0) {
                this.onLoadGridSubData()
              } else {
                this.onLoadGridData()
              }
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sub Kelompok Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onSaveDataSub(data) {
      let item = {}
      item.kode = data.kode
      item.nama = data.nama
      item.group_id = this.kelompokId
      item.sub_id = this.subKelompokId

      this.isLoadingSave = true
      if (data.id > 0) {
        this.dataService
          .updateAccountSGroupWithSub(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Sub Kelompok Account',
                detail: 'Sub Kelompok Account berhasil diperbarui.',
                life: 3000,
              })
              if (this.subKelompokId > 0) {
                this.onLoadGridSubData()
              } else {
                this.onLoadGridData()
              }
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sub Kelompok Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.dataService
          .addAccountSGroupWithSub(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Sub Kelompok Account',
                detail: 'Sub Kelompok Account berhasil ditambahkan.',
                life: 3000,
              })
              if (this.subKelompokId > 0) {
                this.onLoadGridSubData()
              } else {
                this.onLoadGridData()
              }
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Sub Kelompok Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogDelete = true
    },
    onDeleteData() {
      this.dataService
        .deleteAccountSGroup(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Sub Kelompok Account',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            if (this.subKelompokId > 0) {
              this.onLoadGridSubData()
            } else {
              this.onLoadGridData()
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Sub Kelompok Account', this)
        })
      this.form = {}
      this.dialogDelete = false
    },
  },
}
</script>

<style scoped>
.p-toolbar {
  padding: none;
  border: none;
  border-radius: 0;
}
</style>
