<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Giro Keluar</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"> Keuangan / Transaksi / Giro Keluar</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          :loading="isLoadingExportExcel"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined mr-2"
          @click="exportDataExcel"
        />
        <Button
          :loading="isLoadingExportPdf"
          icon="pi pi-print"
          label="Print"
          class="p-button-outlined"
          @click="exportDataPdf"
        />
      </div>
      <grid-giro-keluar
        :items="items"
        :accounts="accounts"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onShowDialog"
      />
    </div>
    <Dialog
      :header="'Edit Giro Keluar'"
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <form-giro-keluar
        :item="form"
        :loading="isLoadingSave"
        :accounts="accounts"
        @save="onSaveData"
        @close="dialogSave = false"
      />
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import AccountService from '@/services/AccountService'
import errorHandler from '@/helpers/error-handler'
import { useAuthStore } from '@/store/auth'
import GridGiroKeluar from '@/components/keuangan/GridGiroKeluar'
import FormGiroKeluar from '@/components/keuangan/FormGiroKeluar'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridGiroKeluar,
    FormGiroKeluar,
  },
  data() {
    return {
      dataService: null,
      dialogSave: false,
      dialogViewer: false,
      faktur: 0,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      isLoading: false,
      isLoadingSave: false,
      isLoadingExportPdf: false,
      isLoadingExportExcel: false,
      items: [],
      accounts: [],
      form: {
        id: 0,
        giro_status: 'outstand',
        giro_status_at: null,
        giro_keterangan: null,
      },
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'penerima_id',
        sortOrder: 1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
  },
  mounted() {
    this.onLoadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateGiroKeluar(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Giro Keluar', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onShowDialog(data) {
      const auth = useAuthStore()
      const roles = ['admin', 'manager', 'edp']
      const exist = auth.user.roles.some((a) => roles.includes(a))
      if (!exist) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Giro Masuk',
          detail: 'Hanya Admin dan Manager yang dapat mengubah data',
          life: 3000,
        })
        return
      }

      this.form = Object.assign({}, data)
      this.form.giro_status_at = this.form.giro_status_at
        ? new Date(this.form.giro_status_at)
        : null
      this.dialogSave = true
    },
    onSaveData(data) {
      let item = {}
      item.id = data.id
      item.giro_status = data.giro_status
      item.giro_status_at = dayjs(data.giro_status_at).format('YYYY-MM-DD')
      item.giro_keterangan = data.giro_keterangan

      this.isLoadingSave = true
      this.dataService
        .updateGiroKeluar(item)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Giro Keluar',
              detail: 'Giro Keluar berhasil diperbarui.',
              life: 3000,
            })
            this.onLoadGridData()
            this.dialogSave = false
          }
        })
        .catch((err) => {
          errorHandler(err, 'Giro Keluar', this)
        })
        .finally(() => (this.isLoadingSave = false))
    },
    exportDataPdf() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoadingExport = true
      this.dataService
        .exportGiroKeluarPdf(sortField, sortOrder, filters)
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Export Giro Keluar',
              detail: 'Gagal mengunduh Giro Keluar.',
              life: 3000,
            })
          } else {
            const blob = new Blob([res.data], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(blob)
            this.pdfSource = pdfUrl + '#toolbar=0'
            this.pdfFilename = `giro-keluar.pdf`
            this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
            this.dialogViewer = true
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Giro Keluar', this)
        })
      this.isLoadingExportPdf = false
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    exportDataExcel() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoadingExportExcel = true
      this.dataService
        .exportGiroKeluarExcel(sortField, sortOrder, filters)
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Export Giro Keluar',
              detail: 'Gagal mengunduh Giro Keluar.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'giro-keluar.xls')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Giro Keluar', this)
        })
        .finally(() => (this.isLoadingExportExcel = false))
    },
  },
}
</script>
