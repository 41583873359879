<template>
  <div class="card">
    <DataTable :value="items" class="p-datatable-sm" showGridlines scrollable>
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        field="account.nama"
        header="Perkiraan"
        style="min-width: 15rem"
      />
      <Column field="keterangan" header="Keterangan" style="min-width: 15rem" />
      <Column
        field="debit"
        header="Debit"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.debit, 2, 2) }}
        </template>
      </Column>
      <Column
        field="kredit"
        header="Kredit"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.kredit, 2, 2) }}
        </template>
      </Column>
      <Column v-if="showButton">
        <template #body="{ data }">
          <div class="flex justify-content-end align-items-end">
            <Button
              type="button"
              icon="pi pi-pencil"
              class="p-button-outlined p-button-secondary p-button-text mr-2"
              @click="$emit('edit', data)"
            />
            <Button
              type="button"
              icon="pi pi-trash"
              class="p-button-outlined p-button-danger p-button-text"
              @click="$emit('delete', data)"
            />
          </div>
        </template>
      </Column>
      <template #footer>
        <table width="100%" class="foot">
          <tr>
            <td align="right" style="width: calc(100% - 120px)" colspan="2">
              Total
            </td>
            <td align="right" class="text-right">
              {{ formatCurrency(totalDebit, 2, 2) }}
            </td>
            <td align="right" class="text-right">
              {{ formatCurrency(totalKredit, 2, 2) }}
            </td>
            <td v-if="showButton"></td>
          </tr>
          <tr v-if="showButton">
            <td align="right" style="width: calc(100% - 120px)" colspan="2">
              Balance
            </td>
            <td align="right" class="text-right">
              {{ formatCurrency(totalBalance, 2, 2) }}
            </td>
            <td align="right" class="text-right"></td>
          </tr>
        </table>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'

export default {
  components: {},
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {
    totalDebit() {
      return this.items.reduce((acc, item) => acc + item.debit, 0)
    },
    totalKredit() {
      return this.items.reduce((acc, item) => acc + item.kredit, 0)
    },
    totalBalance() {
      return this.totalDebit - this.totalKredit
    },
  },
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>

<style scoped>
.foot {
  font-size: small !important;
  font-weight: 600;
}
</style>
