<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Saldo Awal - Piutang</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          Keuangan / Master / Saldo Awal / Piutang</span
        >
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Saldo Awal - Piutang"
          class="mr-2"
          @click="$router.push({ name: 'SaldoAwalPiutangAdd' })"
        />
        <Button
          :loading="isLoadingTemplateImport"
          icon="pi pi-download"
          label="Template"
          class="p-button-success mr-2"
          @click="downloadTemplate"
        />
        <FileUpload
          :loading="isLoadingImport"
          name="file"
          mode="basic"
          type="file"
          icon="pi pi-upload"
          class="p-button mr-2"
          chooseLabel="Import"
          :customUpload="true"
          @uploader="importFile"
          :maxFileSize="10000000"
        />
        <Button
          :loading="isLoadingExportExcel"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined mr-2"
          @click="exportDataExcel"
        />
        <Button
          :loading="isLoadingExportPdf"
          icon="pi pi-print"
          label="Print"
          class="p-button-outlined"
          @click="exportDataPdf"
        />
      </div>
      <grid-piutang
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onEditData"
      />
    </div>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import SaldoAwalPiutangService from '@/services/SaldoAwalPiutangService'
import GridPiutang from '@/components/keuangan/saldo/GridPiutang'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridPiutang,
    Hotkey,
  },
  data() {
    return {
      dataService: null,
      dialogHapus: false,
      dialogViewer: false,
      faktur: 0,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      isLoading: false,
      isLoadingSave: false,
      isLoadingExportPdf: false,
      isLoadingExportExcel: false,
      isLoadingImport: false,
      isLoadingTemplateImport: false,
      import_file: null,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'si_no',
        sortOrder: 1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new SaldoAwalPiutangService()
  },
  mounted() {
    this.loadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginate(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Saldo Awal Piutang', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.$router.push({
        name: 'SaldoAwalPiutangEdit',
        params: { id: data.pelanggan_id },
      })
    },
    exportDataPdf() {
      this.isLoadingExport = true
      this.dataService
        .exportPdf()
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Export Saldo Awal Piutang',
              detail: 'Gagal mengunduh Saldo Awal Piutang.',
              life: 3000,
            })
          } else {
            const blob = new Blob([res.data], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(blob)
            this.pdfSource = pdfUrl + '#toolbar=0'
            this.pdfFilename = `saldo-awal-piutang.pdf`
            this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
            this.dialogViewer = true
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Saldo Awal Piutang', this)
        })
      this.isLoadingExportPdf = false
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    exportDataExcel() {
      this.isLoadingExportExcel = true
      this.dataService
        .exportExcel()
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Export Saldo Awal Piutang',
              detail: 'Gagal mengunduh Saldo Awal Piutang.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'saldo-awal-piutang.xls')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Saldo Awal Piutang', this)
        })
        .finally(() => (this.isLoadingExportExcel = false))
    },
    importFile(event) {
      this.isLoadingImport = true
      this.dataService
        .import(event.files[0])
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Import File Saldo Awal Piutang',
              detail: 'Berhasil import Saldo Awal Piutang.',
              life: 3000,
            })
            this.loadGridData()
          } else {
            let msg = []
            res.data.data.forEach((value) => {
              msg.push(value.error)
            })
            this.$toast.add({
              severity: 'error',
              summary: `${res.data.message}`,
              detail: `${msg}`,
              life: 4000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Import File Saldo Awal Piutang', this)
        })
        .finally(() => (this.isLoadingImport = false))
    },
    downloadTemplate() {
      this.isLoadingTemplateImport = true
      this.dataService
        .templateImport()
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Download Template Saldo Awal Piutang',
              detail: 'Gagal mengunduh Template Saldo Awal Piutang.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'saldo_piutang.xls')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Download Template Saldo Awal Piutang', this)
        })
        .finally(() => (this.isLoadingTemplateImport = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'SaldoAwalPiutangAdd' })
      }
    },
  },
}
</script>
