<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Transfer Kas</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"> Keuangan / Transaksi / Transfer Kas</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Transfer"
          class="mr-2"
          @click="onShowDialog"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined mr-2"
          @click="exportData"
        />
      </div>
      <grid-transfer
        :items="items"
        :accounts="accounts"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onShowDialog"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      :header="
        form && form.id > 0 ? 'Edit Transfer Kas' : 'Tambah Transfer Kas'
      "
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
    >
      <form-transfer
        :item="form"
        :loading="isLoadingSave"
        :accounts="accounts"
        @save="onSaveData"
        @close="dialogSave = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Transfer Kas"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Kode Transfer <strong>{{ form.kt_no }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="dialogDelete = false"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import AccountService from '@/services/AccountService'
import DaftarKasService from '@/services/DaftarKasService'
import errorHandler from '@/helpers/error-handler'
import GridTransfer from '@/components/keuangan/GridTransfer'
import FormTransfer from '@/components/keuangan/FormTransfer'

export default {
  components: {
    GridTransfer,
    FormTransfer,
  },
  data() {
    return {
      dataService: null,
      dialogSave: false,
      dialogDelete: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      accounts: [],
      form: {
        id: 0,
        kt_at: null,
        kas_id_from: 0,
        kas_id_to: 0,
        nilai: 0,
        keterangan: null,
      },
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'kode',
        sortOrder: 1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
    this.dataKasService = new DaftarKasService()
  },
  mounted() {
    this.onLoadGridData()
    this.onLoadDaftarKas()
  },
  methods: {
    onLoadDaftarKas() {
      this.dataKasService
        .get('?sort=nama')
        .then((res) => {
          this.accounts = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Daftar Kas', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateTransfer(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Transfer Kas', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onShowDialog(data) {
      this.form = Object.assign({}, data)
      this.form.kt_at = this.form.kt_at ? new Date(this.form.kt_at) : null
      this.dialogSave = true
    },
    onSaveData(data) {
      let item = {}
      item.kt_at = dayjs(data.kt_at).format('YYYY-MM-DD')
      item.kas_id_from = data.kas_id_from
      item.kas_id_to = data.kas_id_to
      item.nilai = data.nilai
      item.keterangan = data.keterangan

      this.isLoadingSave = true
      if (data.id > 0) {
        this.dataService
          .updateTransfer(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Transfer Kas',
                detail: 'Transfer Kas berhasil diperbarui.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Transfer Kas', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        this.dataService
          .addTransfer(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Transfer Kas',
                detail: 'Transfer Kas berhasil ditambahkan.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Transfer Kas', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogDelete = true
    },
    onDeleteData() {
      this.dataService
        .deleteTransfer(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Transfer Kas',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.onLoadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Transfer Kas', this)
        })
      this.form = {}
      this.dialogDelete = false
    },
    exportData() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoadingExport = true
      this.dataService
        .exportTransfer(sortField, sortOrder, filters)
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Export Transfer Kas',
              detail: 'Gagal mengunduh Transfer Kas.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'transfer-kas.xls')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Transfer Kas', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
}
</script>
