<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12" v-if="!this.item.level">
          <label> Head Account </label>
          <Dropdown
            v-model="form.head"
            :options="listHeadAccount"
            :filter="true"
            :optionLabel="headLabel"
            optionValue="id"
            class="w-full"
            :class="{ 'p-invalid': v$.form.head.$invalid && submitted }"
            input-class="w-full"
            @change="onLoadKelompokAccount()"
          />
          <small
            v-if="
              (v$.form.head.$invalid && submitted) ||
              v$.form.head.$pending.$response
            "
            class="p-error"
            >{{ v$.form.head.required.$message }}</small
          >
        </div>
        <div class="field col-12" v-else>
          <label> Head Account </label>
          <InputText
            v-model="form.head"
            type="text"
            class="w-full"
            input-class="w-full"
            :disabled="form.id > 0"
          />
        </div>
        <div class="field col-12" v-if="!this.item.level">
          <label> Kelompok Account </label>
          <Dropdown
            v-model="form.kelompok"
            :options="listKelompokAccount"
            :filter="true"
            :optionLabel="kelompokLabel"
            optionValue="id"
            class="w-full"
            input-class="w-full"
            @change="onLoadSubKelompokAccount()"
          />
        </div>
        <div class="field col-12" v-else>
          <label> Kelompok Account </label>
          <InputText
            v-model="form.group"
            type="text"
            class="w-full"
            input-class="w-full"
            :disabled="form.id > 0"
          />
        </div>
        <div class="field col-12" v-if="!this.item.level">
          <label> Sub Kelompok Account </label>
          <Dropdown
            v-model="form.sub"
            :options="listSubKelompokAccount"
            :filter="true"
            :optionLabel="subKelompokLabel"
            optionValue="id"
            class="w-full"
            input-class="w-full"
          />
        </div>
        <div class="field col-12" v-else>
          <label> Sub Kelompok Account </label>
          <InputText
            v-model="form.sgroup"
            type="text"
            class="w-full"
            input-class="w-full"
            :disabled="form.id > 0"
          />
        </div>
        <div class="field col-12 md:col-12">
          <label>Kode </label>
          <InputText
            v-model="form.kode"
            :class="{ 'p-invalid': v$.form.kode.$invalid && submitted }"
            type="text"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.kode.$invalid && submitted) ||
              v$.form.kode.$pending.$response
            "
            class="p-error"
            >{{ v$.form.kode.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Nama</label>
          <InputText
            v-model="form.nama"
            :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.nama.$invalid && submitted) ||
              v$.form.nama.$pending.$response
            "
            class="p-error"
            >{{ v$.form.nama.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import errorHandler from '@/helpers/error-handler'
import AccountService from '@/services/AccountService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listHeadAccount: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      accountService: null,
      acAccount: [],
      listKelompokAccount: [],
      listSubKelompokAccount: [],
      form: this.item,
    }
  },
  created() {
    this.accountService = new AccountService()
  },
  computed: {
    headLabel() {
      return (listHeadAccount) =>
        listHeadAccount.kode + ' - ' + listHeadAccount.nama
    },
    kelompokLabel() {
      return (listKelompokAccount) =>
        listKelompokAccount.kode + ' - ' + listKelompokAccount.nama
    },
    subKelompokLabel() {
      return (listSubKelompokAccount) =>
        listSubKelompokAccount.kode + ' - ' + listSubKelompokAccount.nama
    },
  },
  methods: {
    onLoadKelompokAccount() {
      let parent_id = this.form.head
      this.accountService
        .get('?filter[parent_id]=' + parent_id + '&filter[level]=2&sort=kode')
        .then((res) => {
          this.listKelompokAccount = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadSubKelompokAccount() {
      let parent_id = this.form.kelompok
      this.accountService
        .get('?filter[parent_id]=' + parent_id + '&filter[level]=3&sort=kode')
        .then((res) => {
          this.listSubKelompokAccount = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onCloseForm() {
      this.$emit('close')
    },
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (this.item.level) {
        if (this.item.level === 3) {
          this.$emit('save-sub', this.form)
        } else {
          this.$emit('save-sub-account', this.form)
        }
      } else {
        if (Object.keys(this.item).length === 4) {
          this.$emit('save-kelompok', this.form)
        } else if (Object.keys(this.item).length === 5) {
          this.$emit('save-sub', this.form)
        } else if (Object.keys(this.item).length === 6) {
          this.$emit('save-sub-account', this.form)
        }
      }
    },
  },
  validations() {
    return {
      form: {
        head: {
          required: helpers.withMessage('Head harus diisi.', required),
        },
        kode: {
          required: helpers.withMessage('Kode harus diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
      },
    }
  },
}
</script>
