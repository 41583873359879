<template>
  <div>
    <DataTable
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="kode" header="Kode" style="width: 250px" sortable>
        <template #body="{ data }">
          {{ data.kode }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            placeholder="Kode"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="nama" header="Nama">
        <template #body="{ data }">
          {{ data.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            placeholder="Nama kas/bank"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="batch_no" header="No Batch">
        <template #body="{ data }">
          {{ data.batch_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            placeholder="No Batch"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="expired_at" header="Tgl Expired">
        <template #body="{ data }">
          {{ formatDate(data.expired_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            placeholder="Tgl Expired"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        field="quantity"
        header="Quantity"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ data.quantity }}
        </template>
      </Column>
      <Column
        field="harga"
        header="Harga"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.harga, 2, 2) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 200px;
        "
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'

export default {
  components: {},
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        batch_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        expired_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
