<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Kelompok Account</label>
              <InputText
                v-model="getKelompok"
                type="text"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Sub Kelompok Akun</label>
              <InputText
                v-model="getSubKelompok"
                type="text"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Kode </label>
              <InputText
                v-model="form.kode"
                :class="{ 'p-invalid': v$.form.kode.$invalid && submitted }"
                type="text"
                class="w-full"
                input-class="w-full"
                :disabled="form.id > 0"
              />
              <small
                v-if="
                  (v$.form.kode.$invalid && submitted) ||
                  v$.form.kode.$pending.$response
                "
                class="p-error"
                >{{ v$.form.kode.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    listKelompokAccount: {
      type: Array,
      default() {
        return []
      },
    },
    listSubKelompokAccount: {
      type: Array,
      default() {
        return []
      },
    },
    kelompokId: {
      type: Number,
      default: 0,
    },
    subId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    getKelompok() {
      const kelompok = this.listKelompokAccount.find(
        (a) => a.id === this.kelompokId
      )
      if (kelompok) {
        return kelompok.nama
      }
      return ''
    },
    getSubKelompok() {
      const sub = this.listSubKelompokAccount.find((b) => b.id === this.subId)
      if (sub) {
        return sub.nama
      }
      return ''
    },
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      if (this.subId > 0) {
        this.$emit('savesub', this.form)
      } else {
        this.$emit('save', this.form)
      }
    },
  },
  validations() {
    return {
      form: {
        kode: {
          required: helpers.withMessage('Kode harus diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
      },
    }
  },
}
</script>
