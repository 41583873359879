<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm"
      row-hover
      paginator
      scrollable
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column
        field="nama"
        sort-field="pelanggan_id"
        filter-field="nama"
        header="Pelanggan"
        style="min-width: 15rem"
        headerStyle="min-width: 15rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="saldo"
        header="Saldo"
        type="numeric"
        style="min-width: 90px; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.saldo) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 200px;
        "
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <grid-piutang-detail
          :items="slotProps.data.invoice"
          :show-button="false"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import GridPiutangDetail from '@/components/keuangan/saldo/GridPiutangDetail'

export default {
  components: {
    GridPiutangDetail,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      expandedRows: [],
      filters: {
        nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
