<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-12">
          <label>Giro Status</label>
          <Dropdown
            v-model="form.giro_status"
            :options="[
              { value: 'cair', label: 'cair', disabled: false },
              { value: 'batal', label: 'batal', disabled: false },
              {
                value: 'outstand',
                label: 'outstand',
                disabled: this.item.giro_status !== 'outstand',
              },
            ]"
            :class="{
              'p-invalid': v$.form.giro_status.$invalid && submitted,
            }"
            optionValue="value"
            optionLabel="label"
            optionDisabled="disabled"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.giro_status.$invalid && submitted) ||
              v$.form.giro_status.$pending.$response
            "
            class="p-error"
            >{{ v$.form.giro_status.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Tanggal Proses</label>
          <Calendar
            v-model="form.giro_status_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.giro_status_at.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.giro_status_at.$invalid && submitted) ||
              v$.form.giro_status_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.giro_status_at.required.$message }}</small
          >
        </div>
        <div class="field col-12">
          <label> Keterangan</label>
          <InputText
            v-model="form.giro_keterangan"
            type="text"
            :class="{
              'p-invalid': v$.form.giro_keterangan.$invalid && submitted,
            }"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.giro_keterangan.$invalid && submitted) ||
              v$.form.giro_keterangan.$pending.$response
            "
            class="p-error"
            >{{ v$.form.giro_keterangan.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    accounts: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        giro_status: {
          required: helpers.withMessage('Giro status harus diisi.', required),
        },
        giro_status_at: {
          required: helpers.withMessage(
            'Tanggal Jatuh Tempo harus diisi.',
            required
          ),
        },
        giro_keterangan: {
          required: helpers.withMessage('Keterangan harus diisi.', required),
        },
      },
    }
  },
}
</script>
