<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Kelompok Account</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Master / Kelompok Account</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-between mb-2">
        <Dropdown
          v-model="headId"
          :filter="true"
          :options="list_head_account"
          optionValue="id"
          optionLabel="nama"
          placeholder="Pilih Head Account"
          @change="onLoadGridData"
        />
        <Button
          :disabled="headId === 0"
          icon="pi pi-plus"
          label="Kelompok Account"
          @click="onShowDialog"
        />
      </div>
      <grid-kelompok-account
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onShowDialog"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      :header="
        form && form.id > 0
          ? 'Edit Kelompok Account'
          : 'Tambah Kelompok Account'
      "
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <form-kelompok-account
        :item="form"
        :head-id="headId"
        :loading="isLoadingSave"
        :list-head-account="list_head_account"
        @save="onSaveData"
        @close="dialogSave = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Kelompok Account"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Kode Kelompok <strong>{{ form.kode }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="dialogDelete = false"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import errorHandler from '@/helpers/errorhandler'
import AccountService from '@/services/AccountService'
import GridKelompokAccount from '@/components/keuangan/GridKelompokAccount'
import FormKelompokAccount from '@/components/keuangan/FormKelompokAccount'

export default {
  components: {
    GridKelompokAccount,
    FormKelompokAccount,
  },
  data() {
    return {
      headId: 0,
      dataService: null,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      list_head_account: [],
      submitted: false,
      dialogSave: false,
      dialogDelete: false,
      totalRecords: 0,
      form: {
        id: 0,
        kode: null,
        nama: null,
      },
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
  },
  mounted() {
    this.onLoadHeadAccount()
  },
  methods: {
    onLoadHeadAccount() {
      this.dataService
        .getAccountHead()
        .then((res) => {
          this.list_head_account = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Head Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateAccountGroup(
          this.headId,
          page,
          perPage,
          sortField,
          sortOrder,
          filters
        )
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
    onShowDialog(data) {
      if (this.items !== []) {
        this.form = Object.assign({}, data)
        this.dialogSave = true
      }
    },
    onSaveData(data) {
      let item = {}
      item.kode = data.kode
      item.nama = data.nama

      this.isLoadingSave = true
      if (data.id > 0) {
        item.head_id = data.parent.id
        this.dataService
          .updateAccountGroup(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Kelompok Account',
                detail: 'Kelompok Account berhasil diperbarui.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Kelompok Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        item.head_id = this.headId
        this.dataService
          .addAccountGroup(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Kelompok Account',
                detail: 'Kelompok Account berhasil ditambahkan.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Kelompok Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogDelete = true
    },
    onDeleteData() {
      this.dataService
        .deleteAccountGroup(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Kelompok Account',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.onLoadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
      this.form = {}
      this.dialogDelete = false
    },
  },
}
</script>
