<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-12">
          <label>Kode</label>
          <InputText v-model="form.kode" type="text" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-12">
          <label>Nama</label>
          <InputText v-model="form.nama" type="text" class="w-full" disabled />
        </div>
        <div class="field col-12 md:col-12">
          <label>Atas Nama</label>
          <InputText
            v-model="form.atas_nama"
            type="text"
            class="w-full"
            autofocus
          />
        </div>
        <div class="field col-12 md:col-12">
          <label>Rekening</label>
          <InputText v-model="form.rekening" type="text" class="w-full" />
        </div>
        <div class="field col-12 md:col-12">
          <label>Tanggal</label>
          <Calendar
            v-model="form.saldo_at"
            dateFormat="dd/mm/yy"
            class="w-full"
            input-class="w-full"
          />
        </div>
        <div class="field col-12 md:col-12">
          <label>Saldo *</label>
          <InputNumber
            v-model="form.saldo"
            :minFractionDigits="2"
            mode="decimal"
            class="w-full"
            input-class="w-full"
          />

          <small
            v-if="
              (v$.form.saldo.$invalid && submitted) ||
              v$.form.saldo.$pending.$response
            "
            class="p-error"
            >{{ v$.form.saldo.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        saldo: {
          required: helpers.withMessage('Saldo harus diisi.', required),
        },
      },
    }
  },
}
</script>
