<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Perkiraan Akun</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Master / Perkiraan Akun</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Perkiraan Account"
          class="mr-2"
          @click="onShowDialog"
        />
        <Button
          :loading="isLoadingExport"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined"
          @click="exportData"
        />
      </div>
      <grid-perkiraan-akun
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        :list-head-account="listHeadAccount"
        :list-kelompok-account="listKelompokAccount"
        :list-sub-kelompok-account="listSubKelompokAccount"
        @request="onRequestData"
        @edit="onShowDialog"
        @delete="onConfirmDeletion"
      />
    </div>
    <Dialog
      :header="
        form && form.id > 0
          ? 'Edit Perkiraan Account'
          : 'Tambah Perkiraan Account'
      "
      v-model:visible="dialogSave"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
    >
      <form-perkiraan-account
        :item="form"
        :loading="isLoadingSave"
        :list-head-account="listHeadAccount"
        @save-kelompok="onSaveDataKelompok"
        @save-sub="onSaveDataSub"
        @save-sub-account="onSaveDataSubAccount"
        @close="dialogSave = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Perkiraan Akun"
      v-model:visible="dialogDelete"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Kode Akun <strong>{{ form.kode }}</strong> akan dihapus. Proses
          ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          class="p-button-text mr-2"
          @click="dialogDelete = false"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          class="p-button-text p-button-danger"
          @click="onDeleteData"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/errorhandler'
import { helpers, required } from '@vuelidate/validators'
import AccountService from '@/services/AccountService'
import GridPerkiraanAkun from '@/components/keuangan/GridPerkiraanAkun'
import FormPerkiraanAccount from '@/components/keuangan/FormPerkiraanAccount'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    GridPerkiraanAkun,
    FormPerkiraanAccount,
  },
  data() {
    return {
      dataService: null,
      isLoading: false,
      isLoadingSave: false,
      isLoadingExport: false,
      items: [],
      submitted: false,
      dialogSave: false,
      dialogDelete: false,
      totalRecords: 0,
      listHeadAccount: [],
      listKelompokAccount: [],
      listSubKelompokAccount: [],
      form: {
        id: 0,
        head: 0,
        kelompok: 0,
        sub: 0,
        kode: null,
        nama: null,
      },
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
  },
  mounted() {
    this.onLoadGridData()
    this.onLoadHeadAccount()
    this.onLoadSubKelompokAccount()
    this.onLoadKelompokAccount()
  },
  methods: {
    onLoadHeadAccount() {
      this.dataService
        .getAccountHead()
        .then((res) => {
          this.listHeadAccount = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Head Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadKelompokAccount() {
      this.dataService
        .getAccountGroup()
        .then((res) => {
          this.listKelompokAccount = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onLoadSubKelompokAccount() {
      this.dataService
        .getAccountSGroup()
        .then((res) => {
          this.listSubKelompokAccount = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Kelompok Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateAccount(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Perkiraan Akun', this)
        })
        .finally(() => (this.isLoading = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
    onShowDialog(data) {
      if (!data.id) {
        this.onLoadHeadAccount()
      }
      this.form = Object.assign({}, data)
      this.dialogSave = true
    },
    onSaveDataKelompok(data) {
      let item = {}
      item.kode = data.kode
      item.nama = data.nama

      this.isLoadingSave = true
      if (data.id > 0) {
        item.head_id = data.parent.id
        this.dataService
          .updateAccountGroup(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Perkiraan Akun',
                detail: 'Perkiraan Akun berhasil diperbarui.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Perkiraan Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        item.head_id = data.head
        this.dataService
          .addAccountGroup(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Perkiraan Akun',
                detail: 'Perkiraan Akun berhasil ditambahkan.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Perkiraan Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onSaveDataSub(data) {
      let item = {}
      item.kode = data.kode
      item.nama = data.nama
      item.sub_id = 0
      this.isLoadingSave = true

      if (data.id > 0) {
        item.group_id = data.parent_id
        this.dataService
          .updateAccountSGroupNoSub(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Perkiraan Akun',
                detail: 'Perkiraan Akun berhasil diperbarui.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Perkiraan Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        item.group_id = data.kelompok
        this.dataService
          .addAccountSGroupNoSub(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Perkiraan Akun',
                detail: 'Perkiraan Akun berhasil ditambahkan.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Perkiraan Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onSaveDataSubAccount(data) {
      let item = {}
      item.kode = data.kode
      item.nama = data.nama

      this.isLoadingSave = true
      if (data.id > 0) {
        item.group_id = data.group_id
        item.sub_id = data.parent_id
        this.dataService
          .updateAccountSGroupWithSub(item, data.id)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Perkiraan Akun',
                detail: 'Perkiraan Akun berhasil diperbarui.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Perkiraan Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      } else {
        item.group_id = data.kelompok
        item.sub_id = data.sub
        this.dataService
          .addAccountSGroupWithSub(item)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Perkiraan Akun',
                detail: 'Perkiraan Akun berhasil ditambahkan.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogSave = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Perkiraan Akun', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogDelete = true
    },
    onDeleteData() {
      this.dataService
        .deleteAccountSGroup(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Perkiraan Akun',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            if (this.subKelompokId > 0) {
              this.onLoadGridSubData()
            } else {
              this.onLoadGridData()
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Perkiraan Akun', this)
        })
      this.form = {}
      this.dialogDelete = false
    },
    exportData() {
      this.isLoadingExport = true
      this.dataService
        .export()
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'daftar-perkiraan.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch((err) => {
          errorHandler(err, 'Export perkiraan akun', this)
        })
        .finally(() => (this.isLoadingExport = false))
    },
  },
  validations() {
    return {
      form: {
        date_from: {
          required: helpers.withMessage(
            'Tanggal (dari) harus diisi.',
            required
          ),
        },
        date_to: {
          required: helpers.withMessage('Tanggal (s/d) harus diisi.', required),
        },
      },
    }
  },
}
</script>
