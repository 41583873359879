<script setup>
// import { ref, reactive, onMounted, defineEmits } from 'vue'
import { ref, onMounted } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import FxTable from '@/components/FxTable'
import FormNeracaAwal from '@/components/keuangan/FormNeracaAwal'
import { formatCurrency } from '@/helpers'
import errorHandler from '@/helpers/errorhandler'
import NeracaAwalService from '@/services/NeracaAwalService'
import AccountService from '@/services/AccountService'
import Hotkey from '@/components/Hotkey'
import { saveAs } from 'file-saver'

const toast = useToast()
const dataService = new NeracaAwalService()
const accountService = new AccountService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let dialog = ref(false)
let isLoading = ref(false)
let isLoadingPrint = ref(false)
let isLoadingExport = ref(false)
let totalRecords = ref(0)
let totalDebit = ref(0)
let totalKredit = ref(0)
let totalSelisih = ref(0)
let isLoadingSave = ref(false)
// let isLoadingExport = ref(false)
let form = ref({
  id: 0,
  account_id: null,
  saldo: 0,
})
let accounts = ref([])
let items = ref([])
let options = ref({
  page: 1,
  rows: 10,
  sortField: 'kode',
  sortOrder: -1,
  filters: null,
})

const filters = ref({
  kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
})

onMounted(() => {
  accountService.getAccounts().then((res) => {
    accounts.value = res.data.data
  })
  loadData()
})

function onRequestData(e) {
  options.value = e
  loadData()
}

function add() {
  form.value = {}
  dialog.value = true
}

function loadData() {
  isLoading.value = true
  const page = options.value.page
  const perPage = options.value.rows
  const sortField = options.value.sortField
  const sortOrder = options.value.sortOrder
  const filters = options.value.filters

  dataService
    .paginate(page, perPage, sortField, sortOrder, filters)
    .then((res) => {
      items.value = res.data.data
      totalRecords.value = res.data.total
      totalDebit.value = res.summary.total_debit
      totalKredit.value = res.summary.total_kredit
      totalSelisih.value = res.summary.total_selisih
      isLoading.value = false
    })
    .catch((err) => {
      errorHandler(err, 'Data neraca awal', toast)
    })
    .finally(() => (isLoading.value = false))
}

function onEditData(data) {
  const modul = data.modul.replace('_', ' ').toUpperCase()
  if (data.modul !== 'neraca_awal') {
    toast.add({
      severity: 'warn',
      summary: 'Data Neraca Awal',
      detail: `Untuk merubah account ${data.account_nama} hanya bisa dilakukan di modul ${modul}.`,
      life: 3000,
    })
    return
  }
  data.account_id = data.id
  form.value = Object.assign({}, data)
  dialog.value = true
}

function onSaveData(data) {
  isLoadingSave.value = true
  if (data && data.id === 0) {
    dataService
      .add(data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.add({
            severity: 'success',
            summary: 'Data neraca awal',
            detail: 'Data berhasil disimpan.',
            life: 3000,
          })
          loadData()
          dialog.value = false
        }
      })
      .catch((err) => {
        errorHandler(err, 'Data neraca awal', toast)
      })
      .finally(() => (isLoadingSave.value = false))
  } else if (data && data.id >= 0) {
    dataService
      .update(data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.add({
            severity: 'success',
            summary: 'Data neraca awal',
            detail: 'Data berhasil disimpan.',
            life: 3000,
          })
          loadData()
          dialog.value = false
        }
      })
      .catch((err) => {
        errorHandler(err, 'Data neraca awal', toast)
      })
      .finally(() => (isLoadingSave.value = false))
  }
}

function exportDataPdf() {
  isLoadingPrint.value = true
  dataService
    .print()
    .then((res) => {
      if (res.status !== 200) {
        toast.add({
          severity: 'error',
          summary: 'Export Saldo Awal Hutang',
          detail: 'Gagal mengunduh Saldo Awal Hutang.',
          life: 3000,
        })
      } else {
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(blob)
        pdfSource.value = pdfUrl + '#toolbar=0'
        pdfFilename.value = `saldo-awal-hutang.pdf`
        pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
        dialogViewer.value = true
      }
    })
    .catch((err) => {
      errorHandler(err, 'Export Saldo Awal Hutang', toast)
    })
    .finally(() => (isLoadingPrint.value = false))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function exportDataExcel() {
  isLoadingExport.value = true
  dataService
    .export()
    .then((res) => {
      if (res.status !== 200) {
        toast.add({
          severity: 'error',
          summary: 'Export Saldo Awal Hutang',
          detail: 'Gagal mengunduh Saldo Awal Hutang.',
          life: 3000,
        })
      } else {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'saldo-awal-hutang.xls')
        document.body.appendChild(link)
        link.click()
      }
    })
    .catch((err) => {
      errorHandler(err, 'Export Saldo Awal Hutang', toast)
    })
    .finally(() => (isLoadingExport.value = false))
}

function onTriggerHotkey(payload) {
  if (payload.keyString === 'D') {
    add()
  }
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Neraca Awal</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Master / Neraca Awal</span>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 lg:col-10 lg:col-offset-1">
        <div class="card bg-white">
          <div class="flex justify-content-end mb-2">
            <!-- <Button icon="pi pi-plus" label="Kode" @click="add" /> -->
            <Button
              :loading="isLoadingExport"
              icon="pi pi-file-excel"
              label="Export"
              class="p-button-outlined mr-2"
              @click="exportDataExcel"
            />
            <Button
              :loading="isLoadingPrint"
              icon="pi pi-print"
              label="Print"
              class="p-button-outlined"
              @click="exportDataPdf"
            />
          </div>
          <fx-table
            :items="items"
            :loading="isLoading"
            :total="totalRecords"
            :grid-options="options"
            :filters="filters"
            @request="onRequestData"
          >
            <template #columns>
              <Column
                field="account_kode"
                filter-field="kode"
                sort-field="kode"
                header="Kode"
                style="min-width: 150px"
                sortable
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @keydown.enter="filterCallback()"
                  />
                </template>
                <template #footer>
                  Total Debit :
                  {{
                    totalDebit ? formatCurrency(totalDebit) : formatCurrency(0)
                  }}
                </template>
              </Column>
              <Column
                field="account_nama"
                filter-field="nama"
                sort-field="nama"
                header="Nama"
                style="min-width: 250px"
                sortable
              >
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    @keydown.enter="filterCallback()"
                  />
                </template>
                <template #footer>
                  Total Kredit :
                  {{
                    totalKredit
                      ? formatCurrency(totalKredit)
                      : formatCurrency(0)
                  }}
                </template>
              </Column>
              <Column
                field="saldo"
                header="Saldo"
                style="flex-grow: 1; justify-content: flex-end"
              >
                <template #body="{ data }">
                  {{ formatCurrency(data.saldo) }}
                </template>
                <template #footer>
                  Total Selisih :
                  {{
                    totalSelisih
                      ? formatCurrency(totalSelisih)
                      : formatCurrency(0)
                  }}
                </template>
              </Column>
              <Column style="flex-grow: 1; justify-content: flex-end">
                <template #body="{ data }">
                  <Button
                    type="button"
                    icon="pi pi-pencil"
                    class="p-button-outlined p-button-secondary p-button-text"
                    @click="onEditData(data)"
                  />
                </template>
              </Column>
            </template>
          </fx-table>
        </div>
      </div>
    </div>
    <Dialog
      :header="form && form.id > 0 ? 'Edit kode' : 'Tambah kode'"
      v-model:visible="dialog"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <FormNeracaAwal
        :loading="isLoadingSave"
        :item="form"
        :accounts="accounts"
        @save="onSaveData"
        @close="this.dialog = false"
      />
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>
