<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="formgrid grid">
        <div class="field col-12 md:col-12">
          <label>Barang *</label>
          <AutoComplete
            v-model="form.obat"
            :min-length="2"
            :suggestions="acObat"
            :class="{
              'p-invalid': v$.form.obat.$invalid && submitted,
            }"
            :disabled="this.form.id > 0"
            field="nama"
            class="w-full"
            input-class="w-full"
            @complete="searchObat($event)"
            @item-select="selectObat"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.kode }} - {{ slotProps.item.nama }}<br />
                Stock tersedia:
                {{ slotProps.item.stock_available }}
              </div>
            </template>
          </AutoComplete>
          <small
            v-if="
              (v$.form.obat.$invalid && submitted) ||
              v$.form.obat.$pending.$response
            "
            class="p-error"
            >{{ v$.form.obat.hasKode.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>No Batch *</label>
          <InputText
            v-model="form.batch_no"
            :class="{ 'p-invalid': v$.form.batch_no.$invalid && submitted }"
            type="text"
            class="w-full"
          />
          <small
            v-if="
              (v$.form.batch_no.$invalid && submitted) ||
              v$.form.batch_no.$pending.$response
            "
            class="p-error"
            >{{ v$.form.batch_no.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Tanggal Expired * </label>
          <Calendar
            v-model="form.expired_at"
            dateFormat="dd/mm/yy"
            :class="{
              'p-invalid': v$.form.expired_at.$invalid && submitted,
            }"
            class="w-full"
            input-class="w-full"
          />
          <small
            v-if="
              (v$.form.expired_at.$invalid && submitted) ||
              v$.form.expired_at.$pending.$response
            "
            class="p-error"
            >{{ v$.form.expired_at.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Qty * </label>
          <InputNumber
            v-model="form.quantity"
            :minFractionDigits="0"
            class="w-full"
            input-class="w-full"
          />

          <small
            v-if="
              (v$.form.quantity.$invalid && submitted) ||
              v$.form.quantity.$pending.$response
            "
            class="p-error"
            >{{ v$.form.quantity.required.$message }}</small
          >
        </div>
        <div class="field col-12 md:col-12">
          <label>Harga * </label>
          <InputNumber
            v-model="form.harga"
            :minFractionDigits="0"
            mode="currency"
            currency="IDR"
            locale="id-ID"
            class="w-full"
            input-class="w-full"
          />

          <small
            v-if="
              (v$.form.harga.$invalid && submitted) ||
              v$.form.harga.$pending.$response
            "
            class="p-error"
            >{{ v$.form.harga.required.$message }}</small
          >
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import { helpers, required } from '@vuelidate/validators'
import ObatService from '@/services/ObatService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
      form: this.item,
      acObat: null,
    }
  },
  created() {
    this.obatService = new ObatService()
  },
  mounted() {},
  computed: {},
  methods: {
    searchObat(e) {
      this.obatService
        .get(`?filter[nama]=${e.query}&filter[status]=aktif`)
        .then((res) => {
          this.acObat = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Obat', this)
        })
    },
    selectObat(e) {
      this.form.kode = e.value.kode
      this.form.nama = e.value.nama
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        obat: {
          required: helpers.withMessage('Obat harus diisi.', required),
        },
        batch_no: {
          required: helpers.withMessage('Rekening harus diisi.', required),
        },
        expired_at: {
          required: helpers.withMessage('Tanggal harus diisi.', required),
        },
        quantity: {
          required: helpers.withMessage('Saldo harus diisi.', required),
        },
        harga: {
          required: helpers.withMessage('Saldo harus diisi.', required),
        },
      },
    }
  },
}
</script>
