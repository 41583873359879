<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Head Account</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Keuangan / Master / Head Account</span>
      </div>
    </div>
    <div class="card bg-white">
      <grid-head-account
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onShowEditDialog"
      />
    </div>
    <Dialog
      header="Edit Head Account"
      v-model:visible="dialogEdit"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <form-head-account
        :item="form"
        :loading="isLoadingSave"
        @update="onEditData"
        @close="dialogEdit = false"
      />
    </Dialog>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/errorhandler'
import { helpers, required } from '@vuelidate/validators'
import AccountService from '@/services/AccountService'
import GridHeadAccount from '@/components/keuangan/GridHeadAccount'
import FormHeadAccount from '@/components/keuangan/FormHeadAccount'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    GridHeadAccount,
    FormHeadAccount,
  },
  data() {
    return {
      dataService: null,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      submitted: false,
      dialogEdit: false,
      totalRecords: 0,
      form: {
        id: 0,
        kode: null,
        nama: null,
      },
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
  },
  mounted() {
    this.onLoadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.onLoadGridData()
    },
    onLoadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateAccountHead(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Head Account', this)
        })
        .finally(() => (this.isLoading = false))
    },
    resetForm() {
      this.submitted = false
      this.form.date_to = null
      this.form.date_from = null
    },
    onShowEditDialog(data) {
      this.form = Object.assign({}, data)
      this.dialogEdit = true
    },
    onEditData(data) {
      this.isLoadingSave = true
      if (data) {
        this.dataService
          .updateAccount(data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Head Account',
                detail: 'Head Account berhasil diperbarui.',
                life: 3000,
              })
              this.onLoadGridData()
              this.dialogEdit = false
            }
          })
          .catch((err) => {
            errorHandler(err, 'Head Account', this)
          })
          .finally(() => (this.isLoadingSave = false))
      }
    },
  },
  validations() {
    return {
      form: {
        date_from: {
          required: helpers.withMessage(
            'Tanggal (dari) harus diisi.',
            required
          ),
        },
        date_to: {
          required: helpers.withMessage('Tanggal (s/d) harus diisi.', required),
        },
      },
    }
  },
}
</script>
